
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppPager",
  props: {
    currentPage: Number,
    pagesCount: Number,
    pagerCount: Number,
    showPrevMore: Boolean,
    showNextMore: Boolean,
  },
  computed: {
    pagers(): Array<number> {
      const cases = [
        {
          predicate: this.showPrevMore && !this.showNextMore,
          func: "prevNotNextPages",
        },
        {
          predicate: !this.showPrevMore && this.showNextMore,
          func: "nextNotPrevPages",
        },
        {
          predicate: this.showPrevMore && this.showNextMore,
          func: "prevAndNextPages",
        },
        {
          predicate: !this.showPrevMore && !this.showNextMore,
          func: "notPrevNotNextPages",
        },
      ];

      return cases.reduce(
        (acc, curr) => (curr.predicate ? this[curr.func]() : acc),
        []
      );
    },
  },
  methods: {
    paginateHandler(pageIdx: number): void {
      if (this.currentPage === pageIdx) return;

      this.$emit("paginate", Math.min(pageIdx, this.pagesCount));
    },
    prevNotNextPages(): Array<number> {
      const startNumber = this.pagesCount - this.pagerCount + 1;
      const arrayLength = this.pagesCount - startNumber;

      return [...Array(arrayLength)].map((_, idx) => idx + startNumber);
    },
    nextNotPrevPages(): Array<number> {
      return [...Array(this.pagerCount - 1)].map((_, idx) => idx + 2);
    },
    prevAndNextPages(): Array<number> {
      const startNumber = this.currentPage - Math.floor(this.pagerCount / 2);

      return [...Array(this.pagerCount)].map((_, idx) => idx + startNumber);
    },
    notPrevNotNextPages(): Array<number> {
      return [...Array(this.pagesCount - 2)].map((_, idx) => idx + 2);
    },
  },
});

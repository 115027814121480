import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51399204"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "au-main" }
const _hoisted_2 = { class: "au-main__pages-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheMessagesBox = _resolveComponent("TheMessagesBox")!
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_TheSidebar = _resolveComponent("TheSidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TheMessagesBox),
    _createVNode(_component_TheHeader),
    _createElementVNode("main", _hoisted_1, [
      _createVNode(_component_TheSidebar),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ])
  ], 64))
}
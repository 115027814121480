import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ede3c66e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "au-avatar__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["au-avatar", _ctx.avatarSize])
  }, [
    (_ctx.loadSuccess)
      ? (_openBlock(), _createElementBlock("img", _mergeProps({
          key: 0,
          class: "au-avatar__img"
        }, _ctx.$attrs, { src: _ctx.src }), null, 16, _hoisted_1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
  ], 2))
}
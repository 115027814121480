
import { defineComponent } from "vue";

import HeaderBlock from "@/components/pages/Index/common/HeaderBlock/HeaderBlock.vue";

import { WITHDRAWALS } from "@/frames";
import { IWithdrawalsEvent } from "@/types/withdrawal";

import withdrawalsMixin from "@/mixins/views/withdrawals.mixin";

export default defineComponent({
  name: "Withdrawals",
  mixins: [withdrawalsMixin],
  components: { HeaderBlock },
  mounted() {
    this.$socket.once(WITHDRAWALS, (e: IWithdrawalsEvent) => {
      this.setWithdrawals(e.withdrawals);
    });
  },
});

import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-175560ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "au-table" }
const _hoisted_2 = {
  key: 0,
  class: "au-table__empty-text"
}
const _hoisted_3 = { class: "au-table__items-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "au-table__header"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "au-table__items" }
const _hoisted_7 = {
  key: 0,
  class: "au-table__footer"
}
const _hoisted_8 = {
  key: 1,
  class: "au-table__footer-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppPagination = _resolveComponent("AppPagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.totalSize)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Данные отсутствуют"))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.tableKeys)
              ? (_openBlock(), _createElementBlock("header", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableKeys, (t, i) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: `${t.key}-${i}`,
                      class: _normalizeClass([`au-table__${t.key}`, { 'is-sortable': t.sortable }]),
                      onClick: ($event: any) => (_ctx.setSortKey(t))
                    }, [
                      _createTextVNode(_toDisplayString(t.label) + " ", 1),
                      (t.sortable)
                        ? (_openBlock(), _createElementBlock("i", {
                            key: 0,
                            class: _normalizeClass(_ctx.iconClass(t.sortKey || t.key))
                          }, null, 2))
                        : _createCommentVNode("", true)
                    ], 10, _hoisted_5))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ]),
          (_ctx.hasFooter)
            ? (_openBlock(), _createElementBlock("footer", _hoisted_7, [
                (_ctx.showPagination)
                  ? (_openBlock(), _createBlock(_component_AppPagination, {
                      key: 0,
                      modelValue: _ctx.currentPage,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
                      total: _ctx.totalSize,
                      "per-page": _ctx.perPage,
                      onPaginate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('paginate', $event)))
                    }, null, 8, ["modelValue", "total", "per-page"]))
                  : _createCommentVNode("", true),
                (_ctx.showFooterInfoBlock)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.btnShowAllText) + ": " + _toDisplayString(_ctx.totalSize), 1),
                      (_ctx.totalSize === _ctx.itemsInPage)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showAllOrHideItems(1)))
                          }, " Свернуть "))
                        : (_openBlock(), _createElementBlock("button", {
                            key: 1,
                            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showAllOrHideItems('')))
                          }, "Показать все"))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}

const defaultDuration = 5000;
const defaultType = 'success';

import { defineComponent } from "vue";

export default defineComponent({
  name: 'TheMessageItem',
  props: {
    info: Object,
  },
  data: () => ({
    isShowing: true,
  }),
  computed: {
    messageType() {
      return this.info?.type || defaultType;
    },
    iconClass() {
      const prefix = 'au-icon-circle';
      const iconType = this.messageType === 'error' ? 'close' : 'check';

      return `${prefix}-${iconType}`;
    },
  },
  mounted() {
    setTimeout(() => {
      this.isShowing = false;
      this.$emit('delete');

      this.info.onCloseFunc && this.info.onCloseFunc();
    }, this.info.duration || defaultDuration);
  },
});

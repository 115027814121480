import { IPermission } from "@/types/settings/groups";

interface IPermissionsState {
  permissions: Array<IPermission>,
}

export default {
  state: {
    permissions: [],
  },
  getters: {
    getPermissions(state: IPermissionsState): Array<IPermission> {
      return state.permissions;
    },
  },
  mutations: {
    setPermissions(state: IPermissionsState, permissions: Array<IPermission>) {
      state.permissions = permissions;
    },
  },
}
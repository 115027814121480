
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppButton",
  props: {
    theme: String,
    icon: String,
    iconSuffix: String,
    outline: Boolean,
  },
  computed: {
    htmlTag(): string {
      let tag = "button";

      if (this.$attrs.to) {
        tag = "router-link";
      } else if (this.$attrs.href) {
        tag = "a";
      }

      return tag;
    },
    buttonThemeClass(): string {
      return this.theme ? `au-button--${this.theme}` : "";
    },
  },
});

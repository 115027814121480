import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.htmlTag), _mergeProps(_ctx.$attrs, {
    class: ["au-button", [_ctx.buttonThemeClass, { 'is-outline': _ctx.outline }]],
    type: "button"
  }), {
    default: _withCtx(() => [
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: _normalizeClass(["au-button__icon-prefix", _ctx.icon])
          }, null, 2))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.iconSuffix)
        ? (_openBlock(), _createElementBlock("i", {
            key: 1,
            class: _normalizeClass(["au-button__icon-suffix", _ctx.iconSuffix])
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["class"]))
}
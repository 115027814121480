
import { defineComponent } from "vue";

import AppButton from "@/components/ui/AppButton/AppButton.vue";

export default defineComponent({
  name: "HeaderBlock",
  components: { AppButton },
  props: {
    title: String,
    to: String,
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b9bd910e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "au-messages-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheMessageItem = _resolveComponent("TheMessageItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (m) => {
      return (_openBlock(), _createBlock(_component_TheMessageItem, {
        key: m.id,
        info: m,
        onDelete: ($event: any) => (_ctx.deleteMessage(m.id))
      }, null, 8, ["info", "onDelete"]))
    }), 128))
  ]))
}
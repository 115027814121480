import AppTable from "@/components/ui/AppTable/AppTable.vue";
import AppTag from "@/components/ui/AppTag/AppTag.vue";

import { IWithdrawal } from "@/types/withdrawal";
import { IStatusAttributes } from "@/interfaces";

import DateTime from "@/helpers/DateTime";
import { formatMoney } from "@/helpers/Functions";

import { withdrawalStatuses } from "@/enums/withdrawalStatuses";

export default {
  components: { AppTable, AppTag },
  data: () => ({
    withdrawals: [],
  }),
  methods: {
    setWithdrawals(withdrawals: Array<IWithdrawal>) {
      this.withdrawals = withdrawals.map(
        ({
          id,
          amount,
          created_at,
          balance,
          name,
          payment_type: type,
          status,
        }) => {
          const currDate = new Date(created_at);
          const date = DateTime.format(currDate);
          const time = DateTime.format(currDate, "H:i");
          const statusAttrs: IStatusAttributes = withdrawalStatuses[status];

          return {
            id,
            name,
            amount: formatMoney(+amount, false),
            date: `${date} в ${time} +МСК`,
            balance: formatMoney(+balance, false),
            type,
            status,
            statusTranslated: statusAttrs.text,
          };
        }
      );
    },
    tagAttrs(status: string) {
      const statusDict: IStatusAttributes = withdrawalStatuses[status];

      return { theme: statusDict.tagTheme, dark: statusDict.darkEffect };
    },
  },
}

import { defineComponent } from "vue";

export default defineComponent({
  name: "AppTag",
  props: {
    theme: String,
    dark: Boolean,
  },
  computed: {
    tagClass(): string {
      return this.theme ? `au-tag--${this.theme}` : "";
    },
  },
});

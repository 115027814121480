import Cookies from "./helpers/Cookies";

const isLocalhost = process.env.NODE_ENV === 'development';
export const SOCKET_PORT = isLocalhost ? ':8081' : '';
const hostParts = `${window.location.hostname}`.split('.');
export const SOCKET_HOST = isLocalhost ? window.location.hostname : `gateway.${hostParts[hostParts.length - 2]}.${hostParts[hostParts.length - 1]}`;
export const SOCKET_URL = `${window.location.protocol}//${SOCKET_HOST}${SOCKET_PORT}`;

export const defaultLocale = 'ru-RU';
export const defaultTimezone = 'Europe/Moscow';

export const token = Cookies.getValue('access');
import Cookies from "@/helpers/Cookies";

const notAuthenticated = (to, from, next) => {
  const isAuthenticated = Cookies.getValue('access');

  if (isAuthenticated) {
    next('/');
    return;
  }

  next();
}

export default notAuthenticated;

import { defineComponent } from "vue";

export default defineComponent({
  name: "AppDropdownItem",
  props: {
    command: null,
  },
  inject: ["setCommand"],
});

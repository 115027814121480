export const ABSTRACT_DOCUMENT = "abstract_document";
export const ACCOUNT = "account";
export const ACCOUNT_CREATE = "account_create";
export const ACCOUNT_XML = "account_xml";
export const ADDRESS = "address";
export const AUTH = "auth";
export const AVATAR_UPDATE = "avatar_update";
export const ASSET_BLOCK = "asset_block";
export const ASSET_BLOCK_CREATE = "asset_block_create";
export const ASSET_BLOCK_DELETE = "asset_block_delete";
export const ASSET_BLOCK_UPDATE = "asset_block_update";
export const ASSET_ITEM_DELETE = "asset_item_delete";
export const ASSET_LINK_DELETE = "asset_link_delete";
export const BANNER = "banner";
export const BANNER_CREATE = "banner_create";
export const BANNER_PUBLISH = "banner_publish";
export const BANNER_UPDATE = "banner_update";
export const CHANGE_PASSWORD = "change_password";
export const CERTIFICATE = "certificate";
export const CMS = "cms";
export const COMPANY = "company";
export const COMPANY_UPDATE = "company_update";
export const CONTACT_INFO_UPDATE = "contact_info_update";
export const DASHBOARD = "dashboard";
export const DIRECTORIES = "directories";
export const DOCUMENT = "document";
export const DOCUMENT_CREATE = "document_create";
export const DOCUMENT_UPDATE = "document_update";
export const DOCUMENT_DELETE = "document_delete";
export const DOCUMENT_LICENSE = "document_license";
export const DOCUMENT_LICENSE_CREATE = "document_license_create";
export const DOCUMENT_LICENSE_DELETE = "document_license_delete";
export const DOCUMENT_LICENSE_UPDATE = "document_license_update";
export const DOWNLOAD_WITHDRAWALS_PDF = "download_withdrawals_pdf";
export const DOWNLOAD_WITHDRAWALS_XLS = "download_withdrawals_xls";
export const ENTITY = "entity";
export const EP_DOC_CREATE = "estate_property_document_create";
export const ERROR = "error";
export const ESTATE_PROPERTY_UPDATE = "estate_property_update";
export const ESTATE_PROPERTY_IMAGE_CREATE = "estate_property_image_create";
export const ESTATE_PROPERTY_DOCUMENT_CREATE = "estate_property_document_create";
export const ESTATE_PROPERTY_SCHEMA_CREATE = "estate_property_schema_create";
export const ESTATE_SETTINGS = "estate_settings";
export const EXT_TARIFF_SETTINGS_CREATE = "ext_tariff_settings_create";
export const EXT_TARIFF_SETTINGS_UPDATE = "ext_tariff_settings_update";
export const FILE_DELETE = "file_delete";
export const FILE_UPLOAD = "file_upload";
export const GROUP = "group";
export const GROUP_CREATE = "group_create";
export const GROUP_DELETE = "group_delete";
export const GROUP_UPDATE = "group_update";
export const IMAGE_ROTATE = "image_rotate";
export const INDIVIDUAL_CREATE = "individual_create";
export const INDIVIDUAL_UPDATE = "individual_update";
export const LEGAL_PERSON_CONTACT_UPDATE = "legal_person_contact_update";
export const LOGIN = "login";
export const LOT = "lot";
export const LOT_UPDATE = "lot_update";
export const LOT_ADDITION_UPDATE = "lot_addition_update";
export const LOT_TERMS_CONFIG = "lot_terms_config";
export const LOT_TERMS_CONFIG_CREATE = "lot_terms_config_create";
export const LOT_TERMS_CONFIG_DELETE = "lot_terms_config_delete";
export const LOT_TERMS_CONFIG_UPDATE = "lot_terms_config_update";
export const MARKETING = "marketing";
export const MARKETING_CREATE = "marketing_create";
export const MARKETING_DELETE = "marketing_delete";
export const MARKETING_UPDATE = "marketing_update";
export const MAX_UPLOAD = "max_upload";
export const MAX_UPLOAD_UPDATE = "max_upload_update";
export const MEMBER_DELETE = "member_delete";
export const NOTIFICATION_READ = "notification_read";
export const NOTIFICATIONS = "notifications";
export const OBSERVER_SETTING = "observer_setting";
export const OBSERVER_SETTING_UPDATE = "observer_setting_update";
export const PERMISSIONS = "permissions";
export const POST = "post";
export const POST_CREATE = "post_create";
export const POST_DELETE = "post_delete";
export const POST_UPDATE = "post_update";
export const PRESENTATION = "presentation";
export const PRESENTATION_CREATE = "presentation_create";
export const PRESENTATION_DELETE = "presentation_delete";
export const PRESENTATION_UPDATE = "presentation_update";
export const QUESTION = "question";
export const QUESTION_DELETE = "question_delete";
export const QUESTION_UPDATE = "question_update";
export const RESET_PASSWORD = "reset_password";
export const SALE_CONTRACT = "sale_contract";
export const SALE_CONTRACT_APPROVE = "sale_contract_approve";
export const SALE_CONTRACT_MODERATE = "sale_contract_moderate";
export const SALE_CONTRACT_UPDATE = "sale_contract_update";
export const STAFF_USERS = "staff_users";
export const SUGGESTED_TARIFF_CREATE = "suggested_tariff_create";
export const SUGGESTED_TARIFF_UPDATE = "suggested_tariff_update";
export const TARIFF = "tariff";
export const TEST_ANSWER_DELETE = "test_answer_delete";
export const TEST = "test";
export const TEST_COPY = "test_copy";
export const TEST_CREATE = "test_create";
export const TEST_DELETE = "test_delete";
export const TEST_UPDATE = "test_update";
export const TEST_QUESTION_DELETE = "test_question_delete";
export const TICKET = "ticket";
export const TICKET_CATEGORY = "ticket_category";
export const TICKET_CATEGORY_CREATE = "ticket_category_create";
export const TICKET_CATEGORY_DELETE = "ticket_category_delete";
export const TICKET_CATEGORY_UPDATE = "ticket_category_update";
export const TICKET_MESSAGE_CREATE = "ticket_message_create";
export const TICKET_UPDATE = "ticket_update";
export const USER = "user";
export const USER_AND_PROFILE = "user_and_profile";
export const USER_CREATE = "user_create";
export const USER_UPDATE = "user_update";
export const VALIDATION = "validation";
export const WITHDRAWAL = "withdrawal";
export const WITHDRAWAL_COMMENT = "withdrawal_comment";
export const WITHDRAWAL_COMMENT_CREATE = "withdrawal_comment_create";
export const WITHDRAWAL_MODERATE = "withdrawal_moderate";
export const WITHDRAWALS = "withdrawals";
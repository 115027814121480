import { defaultLocale, defaultTimezone } from '@/consts';

import { declensionMonth } from '@/enums/monthRu';

const lookup = {
  d: {
    key: 'day',
    value: '2-digit',
  },
  D: {
    key: 'weekday',
    value: 'short',
  },
  j: {
    key: 'day',
    value: 'numeric',
  },
  l: {
    key: 'weekday',
    value: 'long',
  },
  N: (date: Date) => date.getDay().toString(),
  S: () => {
    console.warn('"S" key isn\'t implemented');
    return '';
  },
  w: () => {
    console.warn('"w" key isn\'t implemented');
    return '';
  },
  z: () => {
    console.warn('"z" key isn\'t implemented');
    return '';
  },
  W: () => {
    console.warn('"W" key isn\'t implemented');
    return '';
  },
  F: {
    key: 'month',
    value: 'long',
  },
  m: {
    key: 'month',
    value: '2-digit',
  },
  M: {
    key: 'month',
    value: 'short',
  },
  n: {
    key: 'month',
    value: 'numeric',
  },
  t: () => {
    console.warn('"t" key isn\'t implemented');
    return '';
  },
  L: () => {
    console.warn('"L" key isn\'t implemented');
    return '';
  },
  o: {
    key: 'year',
    value: 'numeric',
  },
  Y: {
    key: 'year',
    value: 'numeric',
  },
  y: {
    key: 'year',
    value: '2-digit',
  },
  a: (date: Date, timeZone: string) => {
    return date
      .toLocaleString('en', { hour12: true, hour: 'numeric', timeZone })
      .slice(-2)
      .toLowerCase();
  },
  A: (date: Date, timeZone: string) => {
    return date
      .toLocaleString('en', { hour12: true, hour: 'numeric', timeZone })
      .slice(-2)
      .toUpperCase();
  },
  B: () => {
    console.warn('"B" key isn\'t implemented');
    return '';
  },
  g: (date: Date, timeZone: string) => {
    return date
      .toLocaleString('en', { hour12: true, hour: 'numeric', timeZone })
      .replace(/ [AP]M/g, '');
  },
  G: (date: Date, timeZone: string) => {
    return date
      .toLocaleString('ru', { hour: '2-digit', timeZone })
      .replace(/^0/g, '');
  },
  h: (date: Date, timeZone: string) => {
    return date
      .toLocaleString('en', { hour12: true, hour: '2-digit', timeZone })
      .replace(/ [AP]M/g, '');
  },
  H: {
    key: 'hour',
    value: '2-digit',
  },
  i: (date: Date) => {
    return date
      .toLocaleString('ru', { hour12: false, minute: '2-digit' })
      .padStart(2, '0');
  },
  s: (date: Date) => date.toLocaleTimeString('ru').slice(-2),
  u: () => {
    console.warn('"u" key isn\'t implemented');
    return '';
  },
  v: (date: Date) => date.getMilliseconds(),
  e: () => {
    console.warn('"e" key isn\'t implemented');
    return '';
  },
  I: () => {
    console.warn('"I" key isn\'t implemented');
    return '';
  },
  O: () => {
    console.warn('"O" key isn\'t implemented');
    return '';
  },
  P: () => {
    console.warn('"P" key isn\'t implemented');
    return '';
  },
  T: () => {
    console.warn('"T" key isn\'t implemented');
    return '';
  },
  Z: () => {
    console.warn('"Z" key isn\'t implemented');
    return '';
  },
  c: (date: Date) => date.toISOString(),
  r: () => {
    console.warn('"r" key isn\'t implemented');
    return '';
  },
  U: (date: Date) => date.valueOf(),
};

/**
 * DateTime Helper Class
 */
export default class DateTime {
  static format(date: Date, format = 'd.m.Y', timeZone = defaultTimezone): string {
    const fArr = format.split('');

    return fArr.reduce((str, char) => {
      if (!lookup[char]) {
        return str + char;
      } else if (typeof lookup[char] === 'function') {
        return str + lookup[char](date, timeZone);
      }

      const { key, value } = lookup[char];

      return str + date.toLocaleString(defaultLocale, { [key]: [value], timeZone });
    }, '');
  }

  static differenceInDays(firstDate: string | Date, lastDate: string | Date): number {
    const oneDayInSeconds = 60 * 60 * 24;
    const d1 = new Date(firstDate);
    const d2 = new Date(lastDate);

    if (!d1 || !d2) return 0;

    const difference = d1.getTime() - d2.getTime();

    return Math.ceil(difference / (1000 * oneDayInSeconds));
  }

  static ruDateVerb(date: Date, withTime = false): string {
    const dateTimeStr = new Date(date).toLocaleString(defaultLocale, {
      timeZone: defaultTimezone,
    });
    const [d, time] = dateTimeStr.split(', ');
    const dArr = d.split('.');

    dArr[1] = declensionMonth[dArr[1]];

    let str = `${dArr[0]} ${dArr[1]}, ${dArr[2]}`;

    if (withTime) str += ` ${time}`;

    return str;
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b179b24"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "au-withdrawals"
}
const _hoisted_2 = { class: "au-table__name" }
const _hoisted_3 = { class: "au-table__amount is-green" }
const _hoisted_4 = { class: "au-table__balance" }
const _hoisted_5 = { class: "au-table__type" }
const _hoisted_6 = { class: "au-table__status" }
const _hoisted_7 = { class: "au-table__date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderBlock = _resolveComponent("HeaderBlock")!
  const _component_AppTag = _resolveComponent("AppTag")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_AppTable = _resolveComponent("AppTable")!

  return (_ctx.withdrawals.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_HeaderBlock, {
          to: "/billing/withdrawals",
          title: "Запрос на вывод средств"
        }),
        _createVNode(_component_AppTable, {
          "total-size": _ctx.withdrawals.length,
          "items-in-page": _ctx.withdrawals.length
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.withdrawals, (w) => {
              return (_openBlock(), _createBlock(_component_router_link, {
                class: "au-table__item",
                key: w.id,
                to: `/billing/withdrawals/${w.id}`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(w.name), 1),
                  _createElementVNode("span", _hoisted_3, _toDisplayString(w.amount), 1),
                  _createElementVNode("span", _hoisted_4, _toDisplayString(w.balance), 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(w.type), 1),
                  _createElementVNode("span", _hoisted_6, [
                    _createVNode(_component_AppTag, _normalizeProps(_guardReactiveProps(_ctx.tagAttrs(w.status))), {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(w.statusTranslated), 1)
                      ]),
                      _: 2
                    }, 1040)
                  ]),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(w.date), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["total-size", "items-in-page"])
      ]))
    : _createCommentVNode("", true)
}
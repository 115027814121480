
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import TheNotifications from "@/components/layout/TheNotifications/TheNotifications.vue";
import TheUser from "@/components/layout/TheUser/TheUser.vue";

import { IDashboardEvent } from "@/interfaces";
import { USER_AND_PROFILE } from "@/frames";

export default defineComponent({
  name: "TheHeader",
  components: { TheNotifications, TheUser },
  computed: {
    ...mapGetters({ profile: "getProfile", user: "getUser" }),
  },
  methods: {
    setUserData({ profile, user }) {
      this.$store.commit("setProfile", profile);
      this.$store.commit("setUser", user);
    },
  },
  mounted() {
    this.$socket.once(USER_AND_PROFILE, (e: IDashboardEvent) =>
      this.setUserData(e)
    );
  },
});


import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import AppDropdown from "@/components/ui/dropdown/AppDropdown.vue";
import AppDropdownItem from "@/components/ui/dropdown/AppDropdownItem.vue";

import { INotification } from "@/interfaces";
import { NOTIFICATIONS, NOTIFICATION_READ } from "@/frames";

export default defineComponent({
  name: "TheNotifications",
  components: { AppDropdown, AppDropdownItem },
  data: () => ({
    notifications: [],
  }),
  computed: {
    ...mapGetters({ user: "getUser" }),
  },
  methods: {
    setNotifications(notifications: Array<INotification>) {
      this.notifications = notifications.reverse();
    },
    readOneNotify(id: number) {
      this.$socket.emit(NOTIFICATION_READ, [id]);
    },
    readAll() {
      this.$socket.emit(NOTIFICATION_READ, this.user.id);
    },
  },
  mounted() {
    this.$socket.on(NOTIFICATIONS, ({ notifications }) => {
      this.setNotifications(notifications);
    });
  },
  beforeUnmount() {
    this.$socket.removeListener(NOTIFICATIONS);
  },
});

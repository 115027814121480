import { defaultLocale } from "@/consts";

import { IUser } from "@/types/users";
import { Socket } from "socket.io-client";

import Num from "./Num";

export function youtubeEmbedLink(link: string): string {
  if (link.includes("embed/")) return link;

  const videoId = link.includes("watch?v=")
    ? link.split("watch?v=")[1].split("&")[0]
    : link.split("youtu.be/")[1];

  return videoId ? `https://www.youtube.com/embed/${videoId}` : "";
}

export function getAppeals(fn: string, ln: string, sn: string): Array<string> {
  if (!fn) return [];

  const lastFirstNames = `${ln} ${fn}`;
  const firstLastNames = `${fn} ${ln}`;
  const arr = [fn];

  if (ln) {
    arr.push(lastFirstNames, firstLastNames);

    sn && arr.push(`${lastFirstNames} ${sn}`, `${firstLastNames} ${sn}`);
  }

  sn && arr.push(`${fn} ${sn}`);

  return arr;
}

export const formatMoney = (val: number, hasSuffix = true): string => {
  let formattedValue = Intl.NumberFormat(defaultLocale).format(val);

  if (hasSuffix) formattedValue = `${formattedValue} ₽`;

  return formattedValue;
};

export const getUserFullName = (user: IUser) =>
  `${user.first_name} ${user.last_name}`.trim() || user.email;

export const computedPercentageNum = (key: string, object = null) => ({
  get() {
    return object == null
      ? Num.format(this[key])
      : Num.format(this[object][key]);
  },
  set(val: string) {
    // Не использовать Math.min. Он не работает с числами с плавающей точкой
    val = +val < 100 ? val : "100";

    if (object == null) this[key] = Num.normalizeFormat(val);
    else this[object][key] = Num.normalizeFormat(val);
  },
});

export const splitFileToChunks = (file: File) => {
  const chunkSize = 1024 * 512;
  const chunks = Math.ceil(file.size / chunkSize);
  let chunk = 0;
  let result = [];

  while (chunk < chunks) {
    const offset = chunk * chunkSize;

    result.push(file.slice(offset, offset + chunkSize));
    chunk++;
  }

  return result;
};

export const emitChunksBySocket = (
  sock: Socket,
  file: File,
  file_type: number
) => {
  const fileChunks = splitFileToChunks(file);

  fileChunks.forEach((chunk, idx) => {
    sock.emit("file_upload", {
      file: chunk,
      name: file.name,
      file_type,
      chunk_number: idx,
      total_chunks: fileChunks.length,
    });
  });
};

export const scrollToFirstError = async () => {
  await new Promise((resolve) => setTimeout(resolve, 100));

  const target = document.querySelector(".is-error");

  if (!target) return;

  target.scrollIntoView({ behavior: "smooth", block: "center" });
};

export const percentOfTheNumber = (val: number, percent: number): number => {
  return (val / 100) * percent;
};

export const calculateBudgetByTariffSettings = (
  startingPrice: number,
  auctionPrice: number,
  tariffPercent: number,
  premiumPercent: number
): number => {
  if (!premiumPercent) {
    const price = Math.max(startingPrice, auctionPrice);

    return percentOfTheNumber(price, tariffPercent);
  }

  const firstBudget = percentOfTheNumber(startingPrice, tariffPercent);

  if (auctionPrice < startingPrice) return firstBudget;

  const priceDifference = auctionPrice - startingPrice;
  const secondBudget = percentOfTheNumber(priceDifference, premiumPercent);

  return firstBudget + secondBudget;
};

export const downloadFile = (file: File, type: string, fileName: string) => {
  let a = document.createElement("a");
  document.body.appendChild(a);

  const blob = new Blob([file], { type }),
    url = window.URL.createObjectURL(blob);

  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
}

import { ILink } from "@/interfaces";

import { mapGetters } from "vuex";

import { defineComponent } from "vue";

const links: Array<ILink> = [
  {
    text: "Рабочий стол",
    subLinks: [
      {
        text: "Рабочий стол",
        route: "/",
        icon: "desktop.svg",
      },
    ],
  },
  {
    text: "Лоты",
    subLinks: [
      {
        text: "Лоты",
        route: "/lots",
        icon: "lots.svg",
      },
      {
        text: "Сделки",
        route: "/sale-contracts",
        icon: "sale-contracts.svg",
      },
      {
        text: "Тарифы",
        route: "/tariffs",
        icon: "tariffs.svg",
      },
      {
        text: "Условия аукциона",
        route: "/lots/configurations",
        icon: "lot-conditions.svg",
      },
      {
        text: "Наблюдатели",
        route: "/lots/observers",
        icon: "observers.svg",
      },
      {
        text: "Лимит загрузки документов",
        route: "/lots/max-upload-values",
        icon: "download.svg",
      },
    ],
  },
  {
    text: "Финансы",
    subLinks: [
      {
        text: "Транзакции",
        route: "/billing/payment-history",
        icon: "money.svg",
      },
      {
        text: "Вывод средств",
        route: "/billing/withdrawals",
        icon: "withdrawal.svg",
      },
    ],
  },
  {
    text: "Пользователи и компании",
    subLinks: [
      {
        text: "Пользователи",
        route: "/users",
        icon: "users.svg",
      },
      {
        text: "Группы пользователей",
        route: "/settings/groups",
        icon: "groups.svg",
      },
      {
        text: "Компании",
        route: "/companies",
        icon: "company.svg",
      },
    ],
  },
  {
    text: "Сообщения",
    subLinks: [
      {
        text: "Сообщения",
        route: "/feedback/questions",
        icon: "message.svg",
      },
      {
        text: "Обратная связь",
        route: "/feedback/ticket",
        icon: "feedback.svg",
      },
      {
        text: "Категории тикета",
        route: "/feedback/ticket-category",
        icon: "ticket.svg",
      },
    ],
  },
  {
    text: "Материалы",
    subLinks: [
      {
        text: "Статьи",
        route: "/media/posts",
        icon: "articles.svg",
      },
      {
        text: "Презентации",
        route: "/media/presentations",
        icon: "presentations.svg",
      },
      {
        text: "Материалы",
        route: "/media/brand-assets",
        icon: "materials.svg",
      },
      {
        text: "Нормативные документы",
        route: "/documents",
        icon: "documents.svg",
      },
      {
        text: "Документы к лицензии",
        route: "/license-documents",
        icon: "license.svg",
      },
      {
        text: "Реклама на портале",
        route: "/settings/advertising",
        icon: "advertising.svg",
      },
    ],
  },
  {
    text: "Тестирование",
    subLinks: [
      {
        text: "Сертификаты",
        route: "/testing/certificates",
        icon: "certificate.svg",
      },
      {
        text: "Тесты",
        route: "/testing/tests",
        icon: "test.svg",
      },
    ],
  },
];

export default defineComponent({
  name: "TheSidebar",
  data: () => ({
    links,
  }),
  computed: {
    ...mapGetters({ profile: "getProfile" }),
  },
});

import { IProfile } from '@/interfaces';
import { IUser } from "@/types/users";

export default {
  state: {
    user: undefined,
    profile: undefined,
    staffUsers: [],
  },
  getters: {
    getProfile(state) {
      return state.profile;
    },
    getUser(state) {
      return state.user;
    },
    getStaffUsers(state) {
      return state.staffUsers;
    },
  },
  mutations: {
    setProfile(state, p: IProfile) {
      state.profile = p;
    },
    setUser(state, u: IUser) {
      state.user = u;
    },
    setStaffUsers(state, users: IUser[]) {
      state.staffUsers = users;
    },
  },
}
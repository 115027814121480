import {
  IDirectories,
  IFileTypes,
  IDefaultType,
  ITariffType,
  IStringToStringType
} from "@/types/directories";

interface IDirectoriesState {
  directories: IDirectories,
}

export default {
  state: {
    directories: {},
  },
  getters: {
    tariffTypes(state: IDirectoriesState): Array<ITariffType> {
      return state.directories.tariff_types;
    },
    fileTypes(state: IDirectoriesState): IFileTypes {
      return state.directories.file_types;
    },
    withdrawalStatuses(state: IDirectoriesState): IStringToStringType {
      return state.directories.withdrawal_statuses;
    },
    lotStatuses(state: IDirectoriesState): IDefaultType {
      return state.directories.lot_statuses;
    },
    mediaTypes(state: IDirectoriesState): IDefaultType {
      return state.directories.media_types;
    },
    saleContractStatuses(state: IDirectoriesState): IDefaultType {
      return state.directories.sale_contract_statuses;
    },
    saleContractStatusesKeys(state: IDirectoriesState): IStringToStringType {
      if (!state.directories.sale_contract_statuses) return {};

      const [
        STATUS_SALE_CONTRACT_DRAFT,
        STATUS_SALE_CONTRACT_CHECK,
        STATUS_SALE_CONTRACT_EDIT,
        STATUS_SALE_CONTRACT_FINISHED,
        STATUS_SALE_CONTRACT_NOT_FINISHED,
        STATUS_SALE_CONTRACT_TERMINATION
      ] = Object.values(state.directories.sale_contract_statuses);

      return {
        draft: STATUS_SALE_CONTRACT_DRAFT,
        check: STATUS_SALE_CONTRACT_CHECK,
        edit: STATUS_SALE_CONTRACT_EDIT,
        finished: STATUS_SALE_CONTRACT_FINISHED,
        notFinished: STATUS_SALE_CONTRACT_NOT_FINISHED,
        termination: STATUS_SALE_CONTRACT_TERMINATION,
      };
    },
  },
  mutations: {
    setDirectories(state: IDirectoriesState, directories: IDirectories) {
      state.directories = directories;
    },
  },
}

import { defineComponent } from "vue";

import HeaderBlock from "@/components/pages/Index/common/HeaderBlock/HeaderBlock.vue";
import AppAvatar from "@/components/ui/AppAvatar/AppAvatar.vue";

import { TICKET } from "@/frames";
import { ITicket, ITicketEvent } from "@/types/ticket";

import DateTime from "@/helpers/DateTime";

export default defineComponent({
  name: "Feedback",
  components: { HeaderBlock, AppAvatar },
  data: () => ({
    tickets: [],
  }),
  methods: {
    setTickets(data: Array<ITicket>) {
      this.tickets = data.map(({ id, appeal, messages }) => {
        const { admin, created_at, text, attachments } = messages.pop();
        const date = new Date(created_at);

        return {
          id,
          userName: admin || appeal,
          text,
          attachments,
          date: DateTime.ruDateVerb(date, true),
        };
      });
    },
  },
  mounted() {
    this.$socket.once(TICKET, (e: ITicketEvent) => this.setTickets(e.ticket));
  },
});

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea95f906"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "au-sidebar"
}
const _hoisted_2 = { class: "au-sidebar__title" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.profile)
    ? (_openBlock(), _createElementBlock("aside", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (l) => {
          return (_openBlock(), _createElementBlock("div", {
            key: l.text,
            class: "au-sidebar__links-wrapper"
          }, [
            _createElementVNode("p", _hoisted_2, _toDisplayString(l.text), 1),
            (l.subLinks)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(l.subLinks, (subLink) => {
                  return (_openBlock(), _createBlock(_component_router_link, {
                    to: subLink.route,
                    key: subLink.route,
                    class: "au-sidebar__link"
                  }, {
                    default: _withCtx(() => [
                      (subLink.icon)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            src: require(`@/assets/svg/menu-icons/${subLink.icon}`),
                            alt: ""
                          }, null, 8, _hoisted_3))
                        : _createCommentVNode("", true),
                      _createTextVNode(" " + _toDisplayString(subLink.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                }), 128))
              : _createCommentVNode("", true)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
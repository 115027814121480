import { h } from "vue";
import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  RouterView,
} from "vue-router";

import Index from "@/views/Index.vue";

import authenticated from "./guards/authenticated";
import notAuthenticated from "./guards/notAuthenticated";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    beforeEnter: authenticated,
    component: Index,
  },
  {
    path: "/lots",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Lots",
        component: () => import("@/views/lots/Index.vue"),
      },
      {
        path: ":id",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "ShowLot",
            component: () => import("@/views/lots/id/Index.vue"),
          },
          {
            path: "marketing/new",
            name: "NewMarketingEvent",
            component: () => import("@/views/lots/id/marketing/New.vue"),
          },
          {
            path: "marketing/:marketingId",
            name: "EditMarketingEvent",
            component: () => import("@/views/lots/id/marketing/_id.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/lots/configurations",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "LotConditionConfigurations",
        component: () => import("@/views/lots/configurations/Index.vue"),
      },
      {
        path: "new",
        name: "NewLotConditionConfigurations",
        component: () => import("@/views/lots/configurations/New.vue"),
      },
      {
        path: ":id",
        name: "EditLotConditionConfigurations",
        component: () => import("@/views/lots/configurations/_id.vue"),
      },
    ],
  },
  {
    path: "/lots/observers",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "ObserversPricesPage",
        component: () => import("@/views/lots/observers/index.vue"),
      },
      {
        path: ":id",
        name: "ShowEditObserverPaymentPrice",
        component: () => import("@/views/lots/observers/_id.vue"),
      },
    ],
  },
  {
    path: "/lots/max-upload-values",
    name: "MaxUploadValues",
    beforeEnter: authenticated,
    component: () => import("@/views/lots/max-upload-values.vue"),
  },
  {
    path: "/users",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Users",
        component: () => import("@/views/users/Index.vue"),
      },
      {
        path: "new",
        name: "NewUser",
        component: () => import("@/views/users/New.vue"),
      },
      {
        path: ":id",
        name: "EditUser",
        component: () => import("@/views/users/_id/Index.vue"),
      },
      {
        path: ":id/change-password",
        name: "ChangeUserPassword",
        component: () => import("@/views/users/_id/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/billing/payment-history",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "PaymentHistory",
        component: () => import("@/views/billing/payment-history/Index.vue"),
      },
      {
        path: ":id",
        name: "ShowPaymentHistory",
        component: () => import("@/views/billing/payment-history/_id.vue"),
      },
    ],
  },
  {
    path: "/billing/withdrawals",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Withdrawals",
        component: () => import("@/views/billing/withdrawals/Index.vue"),
      },
      {
        path: ":id",
        name: "ShowWithdrawals",
        component: () => import("@/views/billing/withdrawals/_id.vue"),
      },
    ],
  },
  {
    path: "/companies",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Companies",
        component: () => import("@/views/companies/Index.vue"),
      },
      {
        path: ":id",
        name: "ShowCompany",
        component: () => import("@/views/companies/_id.vue"),
      },
    ],
  },
  {
    path: "/sale-contracts",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "SaleContracts",
        component: () => import("@/views/sale-contracts/Index.vue"),
      },
      {
        path: ":id",
        name: "ShowSaleContract",
        component: () => import("@/views/sale-contracts/_id/Index.vue"),
      },
      {
        path: ":id/edit",
        name: "EditSaleContract",
        component: () => import("@/views/sale-contracts/_id/Edit.vue"),
      },
    ],
  },
  {
    path: "/tariffs",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Tariffs",
        component: () => import("@/views/tariffs/Index.vue"),
      },
      {
        path: "new",
        name: "NewTariff",
        component: () => import("@/views/tariffs/New.vue"),
      },
      {
        path: ":id",
        name: "TariffsId",
        component: () => import("@/views/tariffs/_id.vue"),
      },
    ],
  },
  {
    path: "/documents",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Documents",
        component: () => import("@/views/documents/Index.vue"),
      },
      {
        path: ":id",
        name: "EditDocument",
        component: () => import("@/views/documents/_id.vue"),
      },
      {
        path: "new",
        name: "NewDocument",
        component: () => import("@/views/documents/New.vue"),
      },
    ],
  },
  {
    path: "/license-documents",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "LicenseDocuments",
        component: () => import("@/views/license-documents/Index.vue"),
      },
      {
        path: ":id",
        name: "EditLicenseDocument",
        component: () => import("@/views/license-documents/_id.vue"),
      },
      {
        path: "new",
        name: "NewLicenseDocument",
        component: () => import("@/views/license-documents/New.vue"),
      },
    ],
  },
  {
    path: "/feedback/questions",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "QuestionsFromContactPage",
        component: () => import("@/views/feedback/questions/Index.vue"),
      },
      {
        path: ":id",
        name: "ShowQuestionFromContactPage",
        component: () => import("@/views/feedback/questions/_id.vue"),
      },
    ],
  },
  {
    path: "/feedback/ticket",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Ticket",
        component: () => import("@/views/feedback/ticket/Index.vue"),
      },
      {
        path: ":id",
        name: "ShowTicket",
        component: () => import("@/views/feedback/ticket/_id.vue"),
      },
    ],
  },
  {
    path: "/feedback/ticket-category",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "TicketCategory",
        component: () => import("@/views/feedback/ticket-category/Index.vue"),
      },
      {
        path: "new",
        name: "NewTicketCategory",
        component: () => import("@/views/feedback/ticket-category/New.vue"),
      },
      {
        path: ":id",
        name: "ShowTicketCategory",
        component: () => import("@/views/feedback/ticket-category/_id.vue"),
      },
    ],
  },
  {
    path: "/settings/groups",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Groups",
        component: () => import("@/views/settings/groups/Index.vue"),
      },
      {
        path: "new",
        name: "NewGroups",
        component: () => import("@/views/settings/groups/New.vue"),
      },
      {
        path: ":id",
        name: "ShowGroup",
        component: () => import("@/views/settings/groups/_id.vue"),
      },
    ],
  },
  {
    path: "/settings/advertising",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "AdvertisingList",
        component: () => import("@/views/settings/advertising/Index.vue"),
      },
      {
        path: "new",
        name: "NewAdvertising",
        component: () => import("@/views/settings/advertising/New.vue"),
      },
      {
        path: ":id",
        name: "ShowAdvertising",
        component: () => import("@/views/settings/advertising/_id.vue"),
      },
    ],
  },
  {
    path: "/media/posts",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Posts",
        component: () => import("@/views/media/posts/Index.vue"),
      },
      {
        path: ":id",
        name: "EditPost",
        component: () => import("@/views/media/posts/_id.vue"),
      },
      {
        path: "new",
        name: "NewPost",
        component: () => import("@/views/media/posts/New.vue"),
      },
    ],
  },
  {
    path: "/media/brand-assets",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "BrandAssets",
        component: () => import("@/views/media/brand-assets/Index.vue"),
      },
      {
        path: ":id",
        name: "EditBrandAsset",
        component: () => import("@/views/media/brand-assets/_id.vue"),
      },
      {
        path: "new",
        name: "NewBrandAsset",
        component: () => import("@/views/media/brand-assets/New.vue"),
      },
    ],
  },
  {
    path: "/media/presentations",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Presentations",
        component: () => import("@/views/media/presentations/Index.vue"),
      },
      {
        path: "new",
        name: "NewPresentation",
        component: () => import("@/views/media/presentations/New.vue"),
      },
      {
        path: ":id",
        name: "ShowPresentation",
        component: () => import("@/views/media/presentations/_id.vue"),
      },
    ],
  },
  {
    path: "/testing/certificates",
    beforeEnter: authenticated,
    name: "Certificates",
    component: () => import("@/views/testing/Certificates.vue"),
  },
  {
    path: "/testing/tests",
    beforeEnter: authenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "",
        name: "Tests",
        component: () => import("@/views/testing/tests/Index.vue"),
      },
      {
        path: "new",
        name: "NewTest",
        component: () => import("@/views/testing/tests/New.vue"),
      },
      {
        path: ":id",
        name: "EditTest",
        component: () => import("@/views/testing/tests/_id.vue"),
      },
    ],
  },
  {
    path: "/auth",
    beforeEnter: notAuthenticated,
    component: { render: () => h(RouterView) },
    children: [
      {
        path: "login",
        name: "Login",
        component: () => import("@/views/auth/Login.vue"),
      },
      {
        path: "restore",
        name: "Restore",
        component: () => import("@/views/auth/Restore.vue"),
      },
    ],
  },
  {
    path: "/401",
    name: "AccessDenied",
    component: () => import("@/views/ErrorPage.vue"),
  },
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("@/views/ErrorPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ top: 0 }),
});

export default router;


import { defineComponent } from "vue";

import { DASHBOARD } from "@/frames";

import { ILot } from "@/types/lots/index";

export default defineComponent({
  name: "LotCards",
  data: () => ({
    activeLotsCount: 0,
    completedLotsCount: 0,
    totalLotsCount: 0,
  }),
  methods: {
    setTotalLots(lots: Array<ILot>) {
      this.totalLotsCount = lots.length;
    },
    setCompletedLots(lots: Array<ILot>) {
      const completedStatuses = [4, 6];
      const completedLots = lots.filter((lot: ILot) =>
        completedStatuses.includes(lot.status)
      );

      this.completedLotsCount = completedLots.length;
    },
    setActiveLots(lots: Array<ILot>) {
      const activeLots = lots.filter((lot: ILot) => lot.status === 3);

      this.activeLotsCount = activeLots.length;
    },
  },
  mounted() {
    this.$socket.once(DASHBOARD, ({ lots }) => {
      this.setTotalLots(lots);
      this.setCompletedLots(lots);
      this.setActiveLots(lots);
    });
  },
});

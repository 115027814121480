import Cookies from "@/helpers/Cookies";

const authenticated = (to, from, next) => {
  const isAuthenticated = Cookies.getValue('access');

  if (isAuthenticated) {
    next();
    return;
  }

  next('/auth/login');
}

export default authenticated;

import TheMessageItem from "./TheMessageItem/TheMessageItem.vue";

import { mapGetters, mapActions } from "vuex";

import { defineComponent } from "vue";

export default defineComponent({
  name: "TheMessagesBox",
  components: { TheMessageItem },
  computed: {
    ...mapGetters({ messages: "getMessages" }),
  },
  methods: {
    ...mapActions(["deleteMessage"]),
  },
});

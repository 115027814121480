
import AppPagination from "@/components/ui/pagination/AppPagination.vue";

import { ITableKeys } from "@/interfaces";

import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "AppTable",
  emits: ["sort", "paginate"],
  components: { AppPagination },
  props: {
    totalSize: Number,
    perPage: {
      type: Number,
      default: 15,
    },
    hasPagination: {
      type: Boolean,
      default: true,
    },
    itemsInPage: Number,
    btnShowAllText: String,
    tableKeys: Array as PropType<Array<ITableKeys>>,
  },
  data: () => ({
    currentPage: 1,
    sortBy: "",
    sortOrder: "",
  }),
  computed: {
    showPagination(): boolean {
      return this.hasPagination && this.totalSize !== this.itemsInPage;
    },
    showFooterInfoBlock(): boolean {
      return this.btnShowAllText && this.totalSize > this.perPage;
    },
    hasFooter(): boolean {
      return this.showPagination || this.showFooterInfoBlock;
    },
  },
  methods: {
    iconClass(key: string): string {
      if (this.sortBy !== key) return "au-icon-down";

      return this.sortOrder === "asc" ? "au-icon-top" : "au-icon-down";
    },
    setSortKey({ sortable, key, sortKey }): void {
      if (!sortable) return;

      this.sortBy = sortKey || key;
      this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";

      this.$emit("sort", {
        sort_by: this.sortBy,
        sort_order: this.sortOrder,
      });
    },
    showAllOrHideItems(pageNumber: string | number) {
      this.currentPage = 1;
      this.$emit("paginate", pageNumber);
    },
  },
});

import { createStore } from 'vuex';

import users from "./modules/users";
import messages from "./modules/messages";
import directories from "./modules/directories";
import permissions from "./modules/permissions";

export default createStore({
  modules: {
    users,
    messages,
    directories,
    permissions,
  },
})

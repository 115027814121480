import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "show-message" }, {
    default: _withCtx(() => [
      (_ctx.isShowing)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["au-message", `is-${_ctx.messageType}`])
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["au-message__icon", _ctx.iconClass])
            }, null, 2),
            _createElementVNode("p", {
              class: _normalizeClass(["au-message__text", { 'is-bold': _ctx.info.isBold }])
            }, _toDisplayString(_ctx.info.message), 3)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
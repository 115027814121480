
import AppPager from "./AppPager.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "AppPagination",
  components: { AppPager },
  props: {
    modelValue: null,
    total: Number,
    perPage: {
      type: Number,
      default: 10,
    },
    pagerCount: {
      type: Number,
      default: 3,
    },
    hasPager: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    currentPage: {
      get(): number {
        return +this.modelValue;
      },
      set(val: number) {
        this.$emit("update:modelValue", val);
        this.$emit("paginate", val);
      },
    },
    pagesCount(): number {
      return Math.ceil(this.total / this.perPage);
    },
    showPrevMore(): boolean {
      return this.currentPage > this.pagerCount;
    },
    showNextMore(): boolean {
      return this.currentPage < this.pagesCount - this.pagerCount + 1;
    },
  },
  methods: {
    paginateHandler(pageIdx: number): void {
      this.currentPage = pageIdx;
    },
    buttonClick(pageIdx: number, eventName: string): void {
      this.currentPage = pageIdx;
      this.$emit(eventName);
    },
  },
});

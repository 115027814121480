
import { defineComponent } from "vue";

import TheMessagesBox from "@/components/layout/TheMessagesBox/TheMessagesBox.vue";
import TheHeader from "@/components/layout/TheHeader/TheHeader.vue";
import TheSidebar from "@/components/layout/TheSidebar/TheSidebar.vue";

import { IDirectories } from "@/types/directories";
import { IUser } from "@/types/users";
import { IPermission } from "@/types/settings/groups";

import { token } from "@/consts";
import { DIRECTORIES, PERMISSIONS, STAFF_USERS, AUTH, ERROR } from "./frames";

export default defineComponent({
  name: "HomePage",
  components: {
    TheMessagesBox,
    TheHeader,
    TheSidebar,
  },
  beforeMount() {
    this.$socket.once(AUTH, () => {
      if (!token) return;
      this.$socket.emit(AUTH, token);
    });
  },
  mounted() {
    this.$socket.once(DIRECTORIES, (e: IDirectories) => {
      this.$store.commit("setDirectories", e);
    });

    this.$socket.once(PERMISSIONS, (e: IPermission) => {
      this.$store.commit("setPermissions", e);
    });

    this.$socket.once(STAFF_USERS, (e: IUser[]) => {
      this.$store.commit("setStaffUsers", e);
    });

    this.$socket.on(ERROR, (name: string) => {
      console.info(`${ERROR}: ${name}`);

      const redirectMap = {
        DoesNotExist: 404,
        PermissionDenied: 401,
      };

      try {
        this.$router.push(`/${redirectMap[name]}`);
      } catch {
        this.$router.push(`/${redirectMap.PermissionDenied}`);
      }
    });
  },
});

export default {
  state: {
    messages: [],
  },
  getters: {
    getMessages(state) {
      return state.messages;
    },
  },
  mutations: {
    setMessage(state, message) {
      state.messages.push({
        ...message,
        id: Date.now(),
      });
    },
    deleteMessage(state, id) {
      state.messages = state.messages.filter((m) => m.id !== id);
    },
  },
  actions: {
    setMessage({ commit }, message) {
      commit('setMessage', message);
    },
    deleteMessage({ commit }, id) {
      commit('deleteMessage', id);
    },
  }
}

import { defineComponent } from "vue";

export default defineComponent({
  name: "AppAvatar",
  props: {
    size: String,
    src: String,
  },
  data: () => ({
    loadError: false,
    loadSuccess: false,
  }),
  computed: {
    avatarSize(): string {
      return this.size ? `is-${this.size}` : "";
    },
  },
  methods: {
    loadImageHandler() {
      if (!this.src) {
        this.loadSuccess = false;
        return;
      }

      const img = new Image();

      img.onload = () => {
        this.loadSuccess = true;
      };

      // Если будет нужна обработка ошибок - раскомментировать
      // img.onerror = () => (this.loading = false);

      img.src = this.src;
    },
  },
  mounted() {
    this.loadImageHandler();
  },
  watch: {
    src() {
      this.loadImageHandler();
    },
  },
});

/**
 * Number Helper Class
 */
export class Num {
  static parse(val: string | number): number {
    if (typeof val === 'string') {
      val = val.replace(/[^\d,]/g, '').replace(',', '.');
    }

    return +val;
  }

  static format(val: string | number): string {
    if (val === undefined || val === null) return '';

    if (typeof val === 'number') val = val.toString();

    val = val.replace(/[^\d.,]/g, '').replace(/,/g, '.');

    const [whole, part] = val.split('.');

    if (!whole) return '';

    let result = [...whole].reverse().reduce((acc, char, i) => {
      const letter = i !== 0 && i % 3 === 0 ? `${char} ` : char;
      return `${letter}${acc}`;
    }, '');

    if (val.includes('.')) result += '.';
    if (part) result += part.substring(0, 2);

    return result;
  }

  static normalizeFormat(str: string): string {
    const parts = str.replace(/[^\d,.]/g, '').split('.');
    let value = parts[0];

    if (str.includes('.')) value += `.${parts[1].substring(0, 2)}`;

    return value;
  }
}

export default Num;

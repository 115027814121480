import { renderSlot as _renderSlot, vShow as _vShow, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["au-dropdown", { 'is-opened': _ctx.isOpened }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isOpened = !_ctx.isOpened))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createVNode(_Transition, { name: "show-dropdown-items" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("ul", {
          class: _normalizeClass(["au-dropdown__items", _ctx.dropdownItemsClass])
        }, [
          _renderSlot(_ctx.$slots, "dropdown-items", {}, undefined, true)
        ], 2), [
          [_vShow, _ctx.isOpened]
        ])
      ]),
      _: 3
    })
  ], 2))
}
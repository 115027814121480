
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppDropdown",
  emits: ["command", "visible-change"],
  props: {
    placement: {
      type: String,
      default: "right",
    },
    hideByItemClick: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      setCommand: this.setCommand,
    };
  },
  data: () => ({
    isOpened: false,
    command: "",
  }),
  computed: {
    dropdownItemsClass(): string {
      return `is-${this.placement}`;
    },
  },
  methods: {
    setCommand(command: string | number) {
      if (this.hideByItemClick) this.isOpened = false;

      this.command = command;
      this.$emit("command", command);
    },
    close(e: MouseEvent) {
      if (this.$el.contains(e.target)) return;

      this.isOpened = false;
    },
  },
  watch: {
    isOpened() {
      this.$emit("visible-change", this.isOpened);
    },
  },
  // Обработка клика вне области компонента
  mounted() {
    document.addEventListener("click", this.close);
  },
  unmounted() {
    document.removeEventListener("click", this.close);
  },
});

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { io } from "socket.io-client";

import { SOCKET_URL } from "@/consts";
import { maska } from "maska";
import { loading } from "./plugins/Directives";
import CKEditor from "@ckeditor/ckeditor5-vue";

import "@/assets/styles/global.scss";

const socket = io(SOCKET_URL);

socket.on('connect', () => {
  const app = createApp(App);
  app.use(CKEditor);
  app.use(store);
  app.use(router);
  app.config.globalProperties.$socket = socket;

  app.directive("maska", maska);
  app.directive("loading", loading);

  app.mount("#app");
})




import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import AppDropdown from "@/components/ui/dropdown/AppDropdown.vue";
import AppDropdownItem from "@/components/ui/dropdown/AppDropdownItem.vue";

import { IUser } from "@/types/users";

import { getUserFullName } from "@/helpers/Functions";

import Cookies from "@/helpers/Cookies";

export default defineComponent({
  name: "TheUser",
  components: { AppDropdown, AppDropdownItem },
  computed: {
    ...mapGetters({ profile: "getProfile", user: "getUser" }),
  },
  methods: {
    userFullName(user: IUser): string {
      return getUserFullName(user);
    },
    logout() {
      ["access", "refresh"].forEach((c) => Cookies.erase(c));

      this.$router.go();
    },
  },
});

import { ICookiesOptions } from "@/interfaces";

export class Cookies {
  // https://learn.javascript.ru/cookie#setcookie-name-value-options
  static set(name: string, value: string, options: ICookiesOptions) {
    const nameEncoded = encodeURIComponent(name);
    const valueEncoded = encodeURIComponent(value);
    const opts = { path: '/', ...options };

    let updatedCookie = `${nameEncoded}=${valueEncoded}`;

    for (let optionKey in opts) {
      let optionValue = opts[optionKey];

      updatedCookie += "; " + optionKey;

      if (optionValue !== true) updatedCookie += "=" + optionValue;
    }

    document.cookie = updatedCookie;
  }

  static getValue(name: string): string {
    const cookiesArr = document.cookie.split('; ');
    const val = cookiesArr.find((c) => c.includes(name));

    return val && val.split('=').pop();
  }

  static erase(name: string, path = '/') {
    this.set(name, "", { path, 'max-age': -1 });
  }
}

export default Cookies;
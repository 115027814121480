import { IWithdrawalStatusDict } from "@/types/withdrawal";

export const withdrawalStatuses: IWithdrawalStatusDict = {
  pending: {
    text: 'В рассмотрении',
    tagTheme: 'warning',
    darkEffect: false,
  },
  processed: {
    text: 'Выплачено',
    tagTheme: 'success',
    darkEffect: true,
  },
  rejected: {
    text: 'Отклонено админом',
    tagTheme: 'danger',
    darkEffect: true,
  },
  bank_rejected: {
    text: 'Отклонено банком',
    tagTheme: 'danger',
    darkEffect: true,
  },
  canceled: {
    text: 'Отменено пользователем',
    tagTheme: 'danger',
    darkEffect: true,
  },
  admin_approved: {
    text: 'Одобрено админом',
    tagTheme: 'warning',
    darkEffect: false,
  },
  accountant_approved: {
    text: 'Одобрено бухгалтером',
    tagTheme: 'warning',
    darkEffect: false,
  },
}

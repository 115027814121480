
import { defineComponent } from "vue";

import LotCards from "@/components/pages/Index/LotCards/LotCards.vue";
import Withdrawals from "@/components/pages/Index/Withdrawals/Withdrawals.vue";
import Feedback from "@/components/pages/Index/Feedback/Feedback.vue";

import { DASHBOARD, TICKET, WITHDRAWALS } from "@/frames";

const itemsPerPage = 3;

export default defineComponent({
  name: "HomePage",
  components: { LotCards, Withdrawals, Feedback },
  mounted() {
    this.$socket.emit(DASHBOARD, []);
    this.$socket.emit(WITHDRAWALS, { page: 1, per_page: itemsPerPage });
    this.$socket.emit(TICKET, { page: 1, per_page: itemsPerPage });
  },
});

import { createVNode, DirectiveBinding, render } from 'vue';

import AppLoadingSpinner from '@/components/ui/AppLoadingSpinner/AppLoadingSpinner.vue';

const addOrRemoveLoadingClass = (el: HTMLElement, val: boolean) => {
  const method = val ? 'add' : 'remove';
  el.classList[method]('au-loading');
};

const spinnerTemplate = (message: string): Element => {
  const vueComponent = createVNode(AppLoadingSpinner, {
    message,
  });

  render(vueComponent, document.createElement('div'));

  return vueComponent.el as Element;
};

export const loading = {
  beforeMount: (el: HTMLElement, binding: DirectiveBinding) => {
    const message = binding.arg || '';

    addOrRemoveLoadingClass(el, binding.value);

    el.insertAdjacentElement('beforeend', spinnerTemplate(message));
  },
  updated: (el, { value }) => {
    addOrRemoveLoadingClass(el, value);
  }
};